import * as React from 'react'
import {
  updateAgentBankDetails,
  updateAgentBasicDetails,
  updateAgentBusinessDetails,
  updateAgentDetails,
  updateAgentInfoDetails,
  updateAgentLienDetails,
  updateAgentNextOfKinDetails,
  updateAgentRecruiterDetails,
  updateAgentTransactionLimit,
  updateAgentTransferLimit,
} from 'agents/agents.api.client'
import { useMutation, queryCache } from 'react-query'

export default function useUpdateAgent(_originalValues, { notify, alert, clearAlert }) {
  const mountedRef = React.useRef(true)

  const [runUpdate, { status }] = useMutation(updateAgentDetails, {
    onSuccess() {
      notify && notify(`Agent update was successful`)
      queryCache.refetchQueries('agent_details', { force: true })
    },
    onError(error) {
      let message = typeof error === 'string' ? error : JSON.stringify(error, null, 2)
      alert && alert(message || `There was a problem updating the agent's details`)
    },
  })

  const updateAgent = React.useCallback(
    (newValues) => {
      clearAlert && clearAlert()
      if (newValues && Object.keys(newValues).length > 0) {
        runUpdate(newValues)
      }
    },
    [runUpdate, clearAlert]
  )

  React.useEffect(() => {
    return () => (mountedRef.current = false)
  }, [])

  return [{ status }, updateAgent]
}

export const useUpdateAgentBasicDetails = ({ notify, alert, clearAlert }) => {
  const [update, { status }] = useMutation(updateAgentBasicDetails, {
    onSuccess() {
      notify && notify(`Agent details update was successful`)
      queryCache.refetchQueries('agent_details', { force: true })
    },
    onError(error) {
      const message = typeof error === 'string' ? error : JSON.stringify(error, null, 2)
      alert && alert(message || `Error updating agent's details`)
    },
  })

  const updateBasicDetails = React.useCallback(
    (formValues) => {
      clearAlert && clearAlert()

      if (formValues && Object.keys(formValues).length > 0) {
        update(formValues)
      }
    },
    [update, clearAlert]
  )

  return [updateBasicDetails, { status }]
}

export const useUpdateAgentBankDetails = ({ notify, alert, clearAlert }) => {
  const [update, { status }] = useMutation(updateAgentBankDetails, {
    onSuccess() {
      notify && notify(`Agent details update was successful`)
      queryCache.refetchQueries('agent_details', { force: true })
    },
    onError(error) {
      const message = typeof error === 'string' ? error : JSON.stringify(error, null, 2)
      alert && alert(message || `Error updating agent's details`)
    },
  })

  const updateBankDetails = React.useCallback(
    (formValues) => {
      clearAlert && clearAlert()

      if (formValues && Object.keys(formValues).length > 0) {
        update({ ...formValues, channel: 6 })
      }
    },
    [update, clearAlert]
  )

  return [updateBankDetails, { status }]
}

export const useUpdateAgentBusinessDetails = ({ notify, alert, clearAlert }) => {
  const [update, { status }] = useMutation(updateAgentBusinessDetails, {
    onSuccess() {
      notify && notify(`Agent details update was successful`)
      queryCache.refetchQueries('agent_details', { force: true })
    },
    onError(error) {
      const message = typeof error === 'string' ? error : JSON.stringify(error, null, 2)
      alert && alert(message || `Error updating agent's details`)
    },
  })

  const updateBusinessDetails = React.useCallback(
    (formValues) => {
      clearAlert && clearAlert()

      if (formValues && Object.keys(formValues).length > 0) {
        update(formValues)
      }
    },
    [update, clearAlert]
  )

  return [updateBusinessDetails, { status }]
}

export const useUpdateAgentInfoDetails = ({ notify, alert, clearAlert }) => {
  const [update, { status }] = useMutation(updateAgentInfoDetails, {
    onSuccess() {
      notify && notify(`Agent details update was successful`)
      queryCache.refetchQueries('agent_details', { force: true })
    },
    onError(error) {
      const message = typeof error === 'string' ? error : JSON.stringify(error, null, 2)
      alert && alert(message || `Error updating agent's details`)
    },
  })

  const updateInfoDetails = React.useCallback(
    (formValues) => {
      clearAlert && clearAlert()

      if (formValues && Object.keys(formValues).length > 0) {
        update(formValues)
      }
    },
    [update, clearAlert]
  )

  return [updateInfoDetails, { status }]
}

export const useUpdateAgentNOfKinDetails = ({ notify, alert, clearAlert }) => {
  const [update, { status }] = useMutation(updateAgentNextOfKinDetails, {
    onSuccess() {
      notify && notify(`Agent details update was successful`)
      queryCache.refetchQueries('agent_details', { force: true })
    },
    onError(error) {
      const message = typeof error === 'string' ? error : JSON.stringify(error, null, 2)
      alert && alert(message || `Error updating agent's details`)
    },
  })

  const updateNOfKinDetails = React.useCallback(
    (formValues) => {
      clearAlert && clearAlert()

      if (formValues && Object.keys(formValues).length > 0) {
        update(formValues)
      }
    },
    [update, clearAlert]
  )

  return [updateNOfKinDetails, { status }]
}

export const useUpdateAgentTransactionLimit = ({ notify, alert, clearAlert }) => {
  const [update, { status }] = useMutation(updateAgentTransactionLimit, {
    onSuccess() {
      notify && notify(`Agent details update was successful`)
      queryCache.refetchQueries('agent_details', { force: true })
    },
    onError(error) {
      const message = typeof error === 'string' ? error : JSON.stringify(error, null, 2)
      alert && alert(message || `Error updating agent's details`)
    },
  })

  const updateTransLimit = React.useCallback(
    (formValues) => {
      clearAlert && clearAlert()

      if (formValues && Object.keys(formValues).length > 0) {
        update(formValues)
      }
    },
    [update, clearAlert]
  )

  return [updateTransLimit, { status }]
}

export const useUpdateAgentTransferLimit = ({ notify, alert, clearAlert }) => {
  const [update, { status }] = useMutation(updateAgentTransferLimit, {
    onSuccess() {
      notify && notify(`Agent details update was successful`)
      queryCache.refetchQueries('agent_transfer_Limit', { force: true })
    },
    onError(error) {
      const message = typeof error === 'string' ? error : JSON.stringify(error, null, 2)
      alert && alert(message || `Error updating agent's details`)
    },
  })

  const updateTransferLimit = React.useCallback(
    (formValues) => {
      clearAlert && clearAlert()

      if (formValues && Object.keys(formValues).length > 0) {
        update(formValues)
      }
    },
    [update, clearAlert]
  )

  return [updateTransferLimit, { status }]
}

export const useUpdateAgentLienDetails = ({ notify, alert, clearAlert }) => {
  const [update, { status: lienDetailStatus }] = useMutation(updateAgentLienDetails, {
    onSuccess() {
      notify && notify(`Agent details update was successful`)
      queryCache.refetchQueries('agent_details', { force: true })
    },
    onError(error) {
      const message = typeof error === 'string' ? error : JSON.stringify(error, null, 2)
      alert && alert(message || `Error updating agent's details`)
    },
  })

  const updateLienDetails = React.useCallback(
    (formValues) => {
      clearAlert && clearAlert()

      if (formValues && Object.keys(formValues).length > 0) {
        update(formValues)
      }
    },
    [update, clearAlert]
  )

  return [updateLienDetails, { lienDetailStatus }]
}

export const useUpdateAgentRecruiterDetails = ({ notify, alert, clearAlert }) => {
  const [update, { status }] = useMutation(updateAgentRecruiterDetails, {
    onSuccess() {
      notify && notify(`Agent details update was successful`)
      queryCache.refetchQueries('agent_details', { force: true })
    },
    onError(error) {
      const message = typeof error === 'string' ? error : JSON.stringify(error, null, 2)
      alert && alert(message || `Error updating agent's details`)
    },
  })

  const updateRecruiterDetails = React.useCallback(
    (formValues) => {
      clearAlert && clearAlert()

      if (formValues && Object.keys(formValues).length > 0) {
        update(formValues)
      }
    },
    [update, clearAlert]
  )

  return [updateRecruiterDetails, { status }]
}
