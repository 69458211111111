import * as React from 'react'
import Box from 'design/elements/Box'
import Divider from 'design/elements/Divider'
import Spacer from 'design/elements/Spacer'
import Text from 'design/elements/Text'

const LienForm = ({ agent }) => {
  const hasLien = React.useMemo(() => agent?.lien_amount > 0, [agent])

  return (
    <Box>
      <Text>LIEN</Text>
      <Divider mt="0" />
      <Spacer mt="lg" />
      {hasLien ? <Text>Lien placed</Text> : <Text>No Lien placed</Text>}
    </Box>
  )
}

export default LienForm
